.footer {
  background-color: #597b6a;
  color: #edeced;
  flex-shrink: 0;
  padding: 1rem 0;
  border-top: 2px solid #edeced;
  position: relative;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footerNav a,
.socialLinks a {
  color: #edeced;
  text-decoration: none;
  margin-right: 1rem;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footerNav a:hover,
.socialLinks a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.copyright {
  color: #edeced;
  font-size: 0.8rem;
  margin: 0;
}

.languageSelectorWrapper {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footerNav,
  .socialLinks {
    margin-bottom: 0.5rem;
  }

  .footerNav a,
  .socialLinks a {
    margin: 0 0.5rem;
  }

  .copyright {
    margin-top: 0.5rem;
  }

  .languageSelectorWrapper {
    position: static;
    margin-top: 1rem;
  }
}

/* Optional: Add a top border to separate the footer from the main content */
.footer {
  margin-top: 0;
}

/* Optional: Add subtle hover effect for interactive elements */
.footerNav a,
.socialLinks a {
  position: relative;
}

.footerNav a::after,
.socialLinks a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.footerNav a:hover::after,
.socialLinks a:hover::after {
  transform: scaleX(1);
}
