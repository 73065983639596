.centeredContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  padding: 1rem;
}

.centeredContent {
  width: 100%;
  max-width: 64rem;
}
