.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #597b6a; /* Updated from skyblue to #597b6a */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.logoText {
  color: #edeced; /* Updated from #007bff to #edeced for better contrast */
  text-decoration: none;
}

.nav {
  position: relative;
}

.nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin: 0 0.75rem;
  position: relative;
  z-index: 1;
}

.nav ul li a {
  color: #edeced; /* Updated from #333 to #edeced for better contrast */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
  display: inline-block;
}

.nav ul li a:hover,
.nav ul li a.active {
  color: #ffffff; /* Updated from #007bff to white for better visibility */
}

.slider {
  position: absolute;
  bottom: -2px;
  height: 2px;
  background-color: #edeced; /* Updated from #007bff to #edeced */
  transition: all 0.3s ease;
}

.navToggle {
  display: none;
}

@media (max-width: 768px) {
  .header {
    flex-wrap: wrap;
  }

  .navToggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .navIcon {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #333;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .navIcon::before,
  .navIcon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333;
    transition: all 0.3s ease;
  }

  .navIcon::before {
    top: -8px;
  }

  .navIcon::after {
    bottom: -8px;
  }

  .nav {
    display: none;
    width: 100%;
  }

  .navOpen {
    display: block;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav ul li {
    margin: 1rem 0;
  }

  .slider {
    display: none;
  }
}

.logoText {
  display: flex;
  align-items: center;
}

.logoImage {
  height: 30px; /* Adjust as needed */
  margin-right: 10px; /* Space between logo and text */
}
